<template>
  <button v-if="!link" class="c-button">
    <UiIcon :icon="icon" size="lg" />
    <span class="text-xxs md:text-sm"> {{ label }}</span>
  </button>

  <NuxtLink
    v-else
    :to="formatLink(link?.cached_url)"
    :target="link?.target"
    :rel="isExternalLink(link?.cached_url) ? 'external' : undefined"
    class="c-button"
  >
    <UiIcon :icon="icon" size="lg" />
    <span class="text-xxs md:text-sm"> {{ label }}</span>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { Icon } from '@/components/ui/UiIcon.vue'
import type { BlockHeroQuickLinksMenuLinkStoryblok } from '@/types/storyblok'
import { formatLink, isExternalLink } from '@/utils/link-utils'

defineProps<{
  label: string
  icon: Icon
  link?: BlockHeroQuickLinksMenuLinkStoryblok['link']
}>()
</script>

<style scoped lang="postcss">
.c-button {
  @apply text-tertiary ease-out-expo flex w-full flex-col items-center justify-center px-6 text-center transition-colors duration-700 hover:text-white md:w-auto md:flex-row md:gap-[3px];
}
</style>
